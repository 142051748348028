import React from 'react';
import { Link, graphql } from 'gatsby';
import Image from '../components/Common/Image';
import Layout from '../components/Layout';
import { Breadcrumbs } from '../components/Breadcrumbs'
import {
  SubscribeFormBlock,
  TestimonialsBlock,
  LogosBlock,
  ContactInformationBlock
} from '../acf';
import { PostList } from '../components/Posts/PostList';
import SEO from '../components/SEO';
import './category.scss';

const CategoryPage = (props) => {
  const {
    data,
    pageContext,
    location,
  } = props;
  const {
    site,
    allWordpressPost,
    siteSettings,
  } = data;
  const { options } = siteSettings;
  const { blogCategoryHeroImage } = options;
  const { name: categoryName, slug, limit, skip } = pageContext;
  const { siteMetadata } = site;
  const {
    title: siteTitle,
    blogSlug,
    categorySlug,
  } = siteMetadata;
  const pathPrefix = `/${categorySlug}/${slug}`;// /category/baxters-blog/
  // Temporary manual pagination fix here as
  // filtering in the GraphQL page query below was not working
  const postsOnPage = allWordpressPost.edges
    .filter(({node: post}) => post.categories.find(cat => cat.slug === slug) !== undefined)
    .slice(skip, skip + limit);
  return (
    <Layout location={location}>
      <SEO
        title={`${categoryName} | ${siteTitle}`}
        banner={blogCategoryHeroImage && blogCategoryHeroImage.source_url}
      />
      <div className="blog-category-header">
        <Image image={blogCategoryHeroImage} />
        <div className="dark-gradient" />
        <div className="wrap">
          {/* <Breadcrumbs location={location} /> */}
          <h1 className="title">{categoryName}</h1>
        </div>
      </div>
      {/* <div className="breadcrumbs">
        <div className="wrap">
          <span className="crumb">
            <Link to={`/${blogSlug}/`} className="blog-link">Blog</Link>
          </span>
          <span className="crumb">{categoryName}</span>
        </div>
      </div> */}
      <section className="breadcrumbs-container">
        <div className="wrap">
          <Breadcrumbs location={location} />
        </div>
      </section>
      <PostList
        postsOnPage={{edges: postsOnPage}}
        pageContext={pageContext}
        blogSlug={blogSlug}
        pathPrefix={pathPrefix}
      />
      <SubscribeFormBlock
        siteMetadata={siteMetadata}
      />
      <TestimonialsBlock
        hasLink
        link='/contact-us/'
        linkLabel='Book your free consultation now'
        siteMetadata={siteMetadata}
      />
      <LogosBlock
        heading='Some of our valued clients'
        usingGlobalLogos
        siteMetadata={siteMetadata}
      />
      <ContactInformationBlock
        siteMetadata={siteMetadata}
      />
    </Layout>
  )
}

export default CategoryPage

export const pageQuery = graphql`
  query CategoryPage {
    site {
      siteMetadata {
        title
        wordpressUrl
        blogSlug
        categorySlug
        siteUrl
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        blogCategoryHeroImage {
          id
          source_url
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, quality: 70) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`
